import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';
import contactModule from './Modules/ContactModule';
import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}


const FinanceReport: React.FC = () => {
  const endpoint = '/finance-report';
  const title = 'Relatório financeiro';
  const { projectHash, eventHash } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    payment_id: { ref: 'payment_id', column: 'payment_id', label: 'Código' },
    date: { ref: 'date', column: 'date', label: 'Data' },
    payer_id: {
      ref: 'payer_id',
      column: 'payer_id_response',
      includeColumn: 'name',
      label: 'Comprador',
    },
    special_value: { ref: 'special_value', column: 'special_value', label: 'Valor especial' },
    special_text: { ref: 'special_text', column: 'special_text', label: 'Texto especial' },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    payment_id: { model: 'input', type: 'text', name: 'payment_id', label: 'ID de pagamento' },
    project_id: { model: 'input', type: 'text', name: 'project_id', label: 'Projeto' },

    special_value: { model: 'input', type: 'text', name: 'special_value', label: 'Valor' },
    special_text: { model: 'input', type: 'text', name: 'special_text', label: 'Texto' },
    date: { model: 'datePickerHour', type: 'text', name: 'date', label: 'Date' },

  };

  const formValidation: Record<string, ValidationSchema> = {

  };


  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),

    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default FinanceReport;
